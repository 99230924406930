<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产设置</BreadcrumbItem>
                <BreadcrumbItem>所属公司</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="keywords" placeholder="公司名称/地址" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">查询</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="showAddDialog" v-if="permissionButton.add" icon="md-add">新增公司</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList">
                    <template slot-scope="{ row, index }" slot="action">
                        <a @click="editCompany(row,index)" v-if="permissionButton.edit">编辑</a>
                        <Divider type="vertical"/>
                        <a class="errors" @click="delCompany(row,index)">删除</a>
                        <Divider type="vertical"/>
                        <a v-if="row.isDefault === 1" disabled>默认</a>
                        <a @click="setDefaultCompany(row,index)" v-else="row.isDefault === 0">默认</a>
                    </template>
                </Table>
                <editCompany v-model="editCompanyShow" :companyId="companyId" v-on:getCompanyList="getCompanyList"></editCompany>
                <addCompany v-model="addCompanyShow" v-on:getCompanyList="getCompanyList"></addCompany>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[15,20,50,100]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission';
import { reqGetCompanyPage, reqDelCompany, reqSetDefaultCompany } from '../../../../api/asset-api';
import editCompany from '../company/edit';
import addCompany from '../company/add';

export default {
    name: 'companyList',
    components: {
        editCompany,
        addCompany,
    },
    data() {
        return {
            filter: {},
            columns: [
                {
                    title: '公司编码',
                    key: 'encoding',
                    tooltip: 'ture',
                    width: 150
                },
                {
                    title: '公司名称',
                    key: 'name',
                    minWidth: 200
                },
                {
                    title: '公司地址',
                    key: 'address',
                    minWidth: 200
                },
                {
                    title: '联系人',
                    key: 'contact',
                    width: 100
                },
                {
                    title: '联系电话',
                    key: 'phone',
                    width: 150
                },
                {
                    title: '备注',
                    key: 'memo',
                    width: 200
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150
                }
            ],
            companyId: '',
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
            },
            editCompanyShow: false,
            addCompanyShow: false,
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 20,
            loading: false,
            keywords: '',
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getCompanyList();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getCompanyList();
        },
        doSearching(){
            this.pageNum = 1;
            this.getCompanyList();
        },
        //获取分页列表
        getCompanyList() {
            let that = this;
            that.loading = true;
            that.dataList = [];
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                keywords: that.keywords,
            };
            reqGetCompanyPage(para).then((res) => {
                that.loading = false;
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch(() => {
                that.loading = false;
                that.dataList = [];
            });
        },
        //编辑用户
        editCompany(row, index){
            this.companyId = row.id;
            this.editCompanyShow = true;
        },
        //显示添加页面
        showAddDialog: function () {
            this.addCompanyShow = true;
        },
        delCompany(row, index){
            this.$Modal.confirm({
                title: ' ',
                content: '确认删除该公司？',
                width: '240',
                onOk: () => {
                    let para = {companyId: row.id};
                    reqDelCompany(para).then(res => {
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            // 删除数据成功同时刷新grid
                            this.getCompanyList();
                        } else {
                            this.$Message.warning(res.data.msg);
                        }
                    });
                },
            });
        },
        setDefaultCompany(row, index){
            this.$Modal.confirm({
                title: ' ',
                content: '确认将该公司设为默认公司吗？',
                width: '240',
                onOk: () => {
                    let para = {companyId: row.id};
                    reqSetDefaultCompany(para).then(res => {
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            this.getCompanyList();
                        } else {
                            this.$Message.warning(res.data.msg);
                        }
                    });
                },
            });
        }
    },
    mounted() {
        this.getCompanyList();
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
