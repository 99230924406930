<template>
    <Modal v-model="showForm" title="编辑所属公司" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="公司编码" prop="encoding">
                <Input type="text" show-word-limit :maxlength="6" v-model.trim="editForm.encoding" placeholder="请输入"/>
            </FormItem>
            <FormItem label="公司名称" prop="name">
                <Input type="text" show-word-limit :maxlength="50" v-model.trim="editForm.name" placeholder="请输入"/>
            </FormItem>
            <FormItem label="公司地址" prop="address">
                <Input type="text" show-word-limit :maxlength="200" v-model.trim="editForm.address" placeholder="请输入"/>
            </FormItem>
            <FormItem label="联系人" prop="contact">
                <Input type="text" show-word-limit :maxlength="30" v-model.trim="editForm.contact" placeholder="请输入"/>
            </FormItem>
            <FormItem label="联系电话" prop="phone">
                <Input type="text" show-word-limit :maxlength="13" v-model.trim="editForm.phone" placeholder="请输入"/>
            </FormItem>
            <FormItem label="备注" prop="memo">
                <Input type="textarea" show-word-limit :maxlength="500" v-model.trim="editForm.memo"
                       :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { reqGetInfoById, reqEditCompany } from '../../../../api/asset-api';

export default {
    name: "editCompany",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        companyId: ''
    },
    data() {
        let checkPhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系人电话'));
            } else {
                let regPone = null;
                let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
                let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
                if (value.charAt(0) == 0) {    // charAt查长第一个字符方法，用来判断输入的是座机还是手机号
                    regPone = tel;
                } else {
                    regPone = mobile;
                }
                if (!regPone.test(value)) {
                    return callback(
                        new Error("请填写联系人电话(座机格式'区号-座机号码')")
                    );
                }
                callback();
            }
        };
        let checkEncoding = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入公司编码'));
            } else {
                let regEncoding = /^\d{6}$/; //公司编码为6位的数字
                if (value.charAt(0) === '0' && value.charAt(1) === '0') {
                    return callback(new Error("公司编码不能以'00'开头"));
                }
                if (!regEncoding.test(value)) {
                    return callback(
                        new Error('公司编码为6位纯数字')
                    );
                }
                callback();
            }
        };
        return {
            showForm: this.value,
            loading: false,
            editForm: {
                encoding: '',
                name: '',
                address: '',
                contact: '',
                phone: '',
                memo: '',
            },
            oldEncoding: '',
            oldName: '',
            editFormRule: {
                encoding: [
                    {required: true, validator: checkEncoding, trigger: 'blur'},
                ],
                name: [
                    {required: true, message: '请输入公司名称', trigger: 'blur'},
                ],
                address: [
                    {required: true, message: '请输入公司地址', trigger: 'blur'},
                ],
                contact: [
                    {required: true, message: '请输入联系人', trigger: 'blur'},
                ],
                phone: [
                    {required: true, validator: checkPhone, trigger: 'blur'},
                ]
            }
        }
    },
    methods: {
        handleSubmit() {
            let that = this;
            that.$refs['editForm'].validate((valid) => {
                if (valid) {
                    that.loading = true;
                    delete that.editForm['createTime'];
                    delete that.editForm['updatedTime'];
                    let obj = { 'oldEncoding': that.oldEncoding, 'oldName': that.oldName}
                    let para = Object.assign({}, this.editForm, obj)
                    reqEditCompany(para).then(res => {
                        if (res.data.code == 1) {
                            that.loading = false;
                            that.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            that.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            that.$emit('getCompanyList');
                        } else {
                            that.loading = false;
                            that.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error("编辑公司失败");
                    });
                }
            });
        },

        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            this.showForm = val;
            if (val) {
                this.$refs['editForm'].resetFields();
                //通过Id查询角色信息
                reqGetInfoById({companyId: this.companyId}).then(res => {
                    this.editForm = res.data.data;
                    this.oldEncoding = res.data.data.encoding;
                    this.oldName = res.data.data.name;
                });
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
